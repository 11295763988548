/* eslint-disable max-len,no-plusplus,no-param-reassign */
export default {
    easings: {
        linear(t) {
            return t;
        },
        easeInQuad(t) {
            return t * t;
        },
        easeOutQuad(t) {
            return t * (2 - t);
        },
        easeInOutQuad(t) {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        },
        easeInCubic(t) {
            return t * t * t;
        },
        easeOutCubic(t) {
            return (--t) * t * t + 1;
        },
        easeInOutCubic(t) {
            return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
        },
        easeInQuart(t) {
            return t * t * t * t;
        },
        easeOutQuart(t) {
            return 1 - (--t) * t * t * t;
        },
        easeInOutQuart(t) {
            return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
        },
        easeInQuint(t) {
            return t * t * t * t * t;
        },
        easeOutQuint(t) {
            return 1 + (--t) * t * t * t * t;
        },
        easeInOutQuint(t) {
            return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
        },
    },
    init() {
        const navLinks = document.querySelectorAll('[href^="#"]');
        console.log({navLinks});

        if (navLinks.length) {
            navLinks.forEach((link) => {
                link.addEventListener('click', this.initScroll.bind(this));
            });
        }
    },
    initScroll(ev) {
        if (typeof ev.currentTarget.getAttribute('href') === 'undefined') {
            console.warn('[AnimateScroll] Href attribute undefined');
            return;
        }
        if (ev.currentTarget.getAttribute('href') === '#') {
            return;
        }
        if (document.querySelector(ev.currentTarget.getAttribute('href')) === null) {
            console.warn(`[AnimateScroll] Referenced element does not exist - ${ev.currentTarget.getAttribute('href')}`);
            return;
        }

        ev.preventDefault();
        ev.stopImmediatePropagation();

        this.scroll(document.querySelector(ev.currentTarget.getAttribute('href')), 1250, 'easeInQuad');
    },
    getOffsetTop(element) {
        const box = element.getBoundingClientRect();
        const docElem = document.documentElement;

        return box.top + window.pageYOffset - docElem.clientTop;
    },
    scroll(destination, duration = 200, easing = 'linear') {
        document.body.classList.remove("nav-open");

        const start = window.pageYOffset;
        const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

        const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
        const destinationOffset = typeof destination === 'number' ? destination : this.getOffsetTop(destination);

        let destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);

        // if(window.innerWidth > 767){
        //      destinationOffsetToScroll = destinationOffsetToScroll - 90;
        // }

        const easingChoice = this.easings[easing];

        if (!('requestAnimationFrame' in window)) {
            window.scroll(0, destinationOffsetToScroll);
            return;
        }

        function scroll() {
            const now = 'now' in window.performance ? performance.now() : new Date().getTime();
            const time = Math.min(1, ((now - startTime) / duration));
            const timeFunction = easingChoice(time);
            window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));

            if (window.pageYOffset >= (destinationOffsetToScroll - 5)) {
                return;
            }

            requestAnimationFrame(scroll);
        }

        scroll();
    },
};
