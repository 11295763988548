/* globals jQuery, wordpress_globals */

import "../sass/theme.scss";

import $ from 'jquery';
import svgxhr from 'webpack-svgstore-plugin/src/helpers/svgxhr';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import simpleParallax from 'simple-parallax-js';
import SplitType from 'split-type';
import { WOW } from "wowjs";

import AnimateScroll from './modules/animate-scroll';

window.namespace = {
  init: function () {
    this.helpers.initSvg();
    AnimateScroll.init();
    this.setBodyWidth();
    this.initAnimations();
    this.nav();

    if (document.querySelector(".parallax") !== null) {
      document.querySelectorAll(".parallax").forEach((image) => new simpleParallax(image, {
        delay: 0,
        orientation: 'down',
        scale: 1.7,
        overflow: true,
      }))
    }

    //Allow user to interrupt auto-scrolling
    $(document).bind('scroll mousedown wheel keyup', function (e) {
      if (e.which || e.type === 'mousedown' || e.type === 'mousewheel') {
        $(document.scrollingElement).stop();
      }
    });
  },
  nav() {
    if(document.querySelector('#nav-trigger') !== null) {
      document.querySelector('#nav-trigger').addEventListener("click", ev => {
        ev.preventDefault();

        document.scrollingElement.scrollTo({top: 0, left: 0});
        document.body.classList.toggle("nav-open");
      });
    }
  },
  setBodyWidth: () => {
    const innerHeight = window.innerHeight;
    console.log(window.innerHeight);
    document.documentElement.style.setProperty('--body-height', `${innerHeight}px`);
  },
  initAnimations() {
    document.querySelectorAll('.text-animation').forEach((wrapper) => {
      wrapper.querySelectorAll('h1,h2,h3,p').forEach(el => {
        el.splitElements = new SplitType(el, { types: "lines", wordClass: "word animation", lineClass: "line animation"});
        el.splitElements.lines.forEach(el => {
          this.addTextMask(el);
        });
      });
    });

    const wow = new WOW({
      boxClass: 'wow',      // animated element css class (default is wow)
      animateClass: 'animated', // animation css class (default is animated)
      offset: 100,          // distance to the element when triggering the animation (default is 0)
      mobile: true,       // trigger animations on mobile devices (default is true)
      live: true,       // act on asynchronously loaded content (default is true)
      callback: function (box) {
        let delay = 0;

        if(box.classList.contains("text-animation")) {
          box.querySelectorAll('h1,h2,h3,p').forEach(el => {
            if(typeof el.splitElements !== "undefined") {
              el.splitElements.lines.forEach(word => {
                const delayString = `${delay}`.padStart(2, '0');
                word.classList.add(`fadeUpHinge`, `delay--${delayString}`);
                delay += 1;
              });
            }
          })
        }
        // the callback is fired every time an animation is started
        // the argument that is passed in is the DOM node being animated
      },
      scrollContainer: null // optional scroll container selector, otherwise use window
    });
    wow.init();
  },
  addTextMask: (toWrap, wrapper) => {
    wrapper = wrapper || document.createElement('div');
    wrapper.classList.add("text-mask");
    toWrap.parentNode.appendChild(wrapper);
    return wrapper.appendChild(toWrap);
  },
  helpers: {
    requireAll: function (r) {
      r.keys().forEach(r);
    },
    initSvg: function () {
      svgxhr({
        filename: './wp-content/themes/class/dist/sprite.svg', //URL to fetch
      });
    }
  }

  //Prefer creating new modules when appropriate, rather than adding functions here
  //If you need something to be globally accessible, you can assign an imported module to this namespace
  //I.e import Contact from './contact';
  //    window.namespace.Contact = Contact;
};

document.addEventListener("DOMContentLoaded", (ev) => {
  window.namespace.init();
});


if (typeof NodeList.prototype.forEach !== 'function') {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// SVG Sprite Loader
window.namespace.helpers.requireAll(require.context('../images/icons/', true, /\.svg$/));
